window._ = require("lodash");

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

window.Eloca = {
    behaviors: {},

    load: function (context) {
        for (const behavior in this.behaviors) {
            this.behaviors[behavior](context);
        }
    },
};

window.$ = window.jQuery = require("jquery");
require("popper.js");
require("bootstrap");
require("jquery-mask-plugin");
window.Vapor = require("laravel-vapor");
require("../libs/jquery-ui.min.js");
require("../libs/qrcodegen.js");
window.numeral = require("numeral");
require("numeral/locales/pt-br");
numeral.locale("pt-br");

//require("./ckeditor5/ckeditor");
require("./global");
require("./nav");
require("./default");
require("../components/mask");
require("../components/datepicker");
require("./produtos");
require("../components/password");
require("../components/validar-documentos");
require("./oportunidades");

(function ($) {
    Eloca.load(document);
})(jQuery);
